.geral-informations-container {
  width: 100%;
  height: 145vh;
  display: flex;
  justify-content: center;

  .center-banner-geral-informations-container {
    background-color: white;
    width: 70%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    flex-direction: column;

    .scientific-references {
      width: 80%;
      margin-top: 30px;
      display: flex;
      flex-direction: column;

      h1 {
        font-size: 2rem;
        text-align: center;
        font-weight: 500;

        span {
          font-weight: bold;
        }
      }

      .scientific-disclaimer-images {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 10px;

        img {
          width: 60%;
          margin-bottom: 40px;
        }
      }
    }

    .igredient-section {
      width: 100%;
      height: 85%;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-evenly;
      gap: 10px;
    }

    h1 {
      font-size: 1.5rem;
    }

    p {
      font-size: 1.2rem;
      text-align: center;
    }
  }
}

//mobile
@media screen and (max-width: 1020px) {
  .geral-informations-container {
    height: auto;

    .center-banner-geral-informations-container {
      width: 90%;
      height: 100%;

      .scientific-references {
        width: 100%;
        display: flex;
        flex-direction: column;
        position: relative;

        h1 {
          align-self: center;
          margin-top: -25px;
          font-size: 1.2rem;
        }

        .scientific-disclaimer-images {
          flex-direction: column;

          img {
            display: flex;
            width: 100%;
            margin: 0;
          }
        }
      }

      .igredient-section {
        h1 {
          margin-top: 10px;
          font-size: 1rem;
        }
      }

      h1 {
        margin-top: 50px;
        font-size: 1rem;
        max-width: 90%;
      }

      p {
        font-size: 0.8rem;
        max-width: 80%;
      }
    }
  }
}
