.home-body {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  background-color: #dbdbdb;

  .nav-bar {
    background-color: white;
    position: fixed;
    top: 0;
    display: flex;
    align-items: center;
    justify-content: space-around;
    width: 100%;
    height: 90px;
    z-index: 1;

    .nav-bar-list-placeholder {
      ul {
        margin: 0;
        padding: 0;
        display: flex;
        gap: 30px;
        list-style: none;

        li {
          font-size: 1rem;
          text-transform: uppercase;
          letter-spacing: 2px;

          &:hover {
            cursor: pointer;
            text-decoration: underline;
            text-underline-offset: 10px;
          }
        }
      }
    }

    img {
      height: 80px;
    }
  }

  .about {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 80%;
    margin-top: 90px;

    img {
      width: 30%;
      margin-top: 10px;
    }

    img:nth-child(2) {
      width: 40%;
    }

    .brefing-about-the-store-placeholder {
      margin-top: 40px;
      height: 200px;
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 20px;

      .about-question {
        position: relative;
        width: 40%;
        min-height: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        text-align: center;
        border: 1px solid #ffbd59;
        border-radius: 20px;
        background-color: #7baf96;

        h1 {
          position: absolute;
          top: 0;
          font-size: 1.2rem;
          color: #577f6c;
          background-color: white;
          -webkit-border-top-left-radius: 20px;
          -webkit-border-top-right-radius: 20px;
          -moz-border-radius-topleft: 20px;
          -moz-border-radius-topright: 20px;
          border-top-left-radius: 20px;
          border-top-right-radius: 20px;
          width: 100%;
          height: 40px;
          margin: 0;
          display: flex;
          align-items: center;
          justify-content: center;
        }

        h2 {
          font-size: 1.2rem;
          font-weight: 500;
          max-width: 80%;
          color: white;
        }
      }
    }
  }

  .products-list-placeholder {
    margin-top: 40px;
    width: 80%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    h1 {
      font-weight: 500;
      text-transform: uppercase;
      font-size: 1.7rem;
      letter-spacing: 4px;
      color: #577f6c;
    }

    .product-card {
      width: 80%;
      border: 1.7px solid #577f6c;
      border-radius: 20px;
      display: flex;
      justify-content: space-around;
      align-items: center;
      padding: 20px;
      margin: 10px;

      .text-area {
        max-width: 45%;

        h1 {
          font-size: 1.4rem;
          font-weight: bold;
          color: #b0171a;
        }

        p {
          font-weight: 1rem;
          letter-spacing: 1px;
          max-width: 80%;
          margin-bottom: 40px;
        }

        a {
          text-decoration: none;
          text-transform: uppercase;
          padding: 15px;
          background-color: #ffbd59;
          color: black;
          border-radius: 8px;
          align-self: center;
          font-size: 1rem;

          &:hover {
            background-color: #b0171a;
            color: #dbdbdb;
            transition: 0.4s;
          }
        }
      }

      img {
        width: 22%;
      }
    }
  }

  .footer {
    margin-top: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 30px;
    background-color: #577f6c;
    width: 100%;
    height: 40px;

    h1 {
      text-align: center;
      font-size: 0.8rem;
      font-weight: 100;
      color: white;
      letter-spacing: 2px;
    }
  }
}

//mobile
@media screen and (max-width: 1200px) {
  .home-body {
    .nav-bar {
      height: 50px;
      a {
        font-size: 0.8rem;
      }

      img {
        height: 40px;
      }
    }

    .about {

      img {
        width: 40%;
      }

      img:nth-child(2) {
        width: 60%;
      }

      .brefing-about-the-store-placeholder {
        height: auto;
        flex-direction: column;

        .about-question {
          width: 80%;
          height: 200px;

          h1 {
          }

          h2 {
            margin-top: 30px;
            font-size: 1rem;
          }
        }
      }
    }

    .products-list-placeholder {
      margin-top: 0;
      text-align: center;

      h1 {
        margin-top: 50px;
        font-size: 1.3rem;
      }

      .product-card {
        padding: 30px;
        flex-direction: column;
        flex-flow: column-reverse;

        img {
          width: 50%;
        }

        .text-area{
          margin-top: 20px;
          max-width: 95%;

          h1{
            font-size: 1rem;
          }

          p{
            max-width: 95%;
            font-size: 0.8rem;
          }
        }
        
      }
    }

    .footer{
      h1{
        font-size: 0.8rem;
      }

      svg{
        font-size: 1rem;
      }
    }
  }
}


