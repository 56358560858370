.ingredient-card {
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-basis: 30%;
  width: 20%;

  .ingredient-image-placeholder {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 20px;
    background-color: #e1e5ee;
    border-radius: 30px;
    width: 85%;

    img {
      width: 100%;
      border-radius: 30px;
    }
  }

  h1 {
    margin-top: 10px;
    text-align: center;
  }

  p {
    text-align: center;
    font-size: 1.2rem;
    margin: 0;
  }
}

@media screen and (max-width: 1020px) {
.ingredient-card {
  flex-basis: 43%;
  justify-content: space-between;

  .ingredient-image-placeholder {
    padding: 8px;
    
    img {
      display: flex;
    }
  }
}
}
