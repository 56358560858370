.frequently-questions-container {
  width: 100%;
  height: 200vh;
  display: flex;
  justify-content: center;

  .center-banner-frequently-questions {
    width: 70%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    .questions-placeholder {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 100%;
      height: 900px;
      background-color: #2a324b;
      border-radius: 30px;

      h2 {
        color: white;
        font-size: 2rem;
      }
    }

    .last-offer-placeholder {
      margin-top: 50px;
      width: 100%;
      height: 30%;
      background-color: #841b17;
      border-radius: 30px;
      display: flex;
      align-items: center;
      justify-content: space-around;

      .text-zone {
        display: flex;
        flex-direction: column;
        max-width: 50%;
        color: white;

        h1 {
          font-weight: 500;
        }

        p {
          margin-bottom: 30px;
          letter-spacing: 1px;
          max-width: 90%;
        }

        a {
          padding: 15px;
          background-color: #ffd814;
          width: 40%;
          align-self: left;
          text-align: center;
          text-transform: uppercase;
          text-decoration: none;
          font-size: 1.2rem;
          font-weight: bold;
          border-radius: 30px;
        }
      }

      img {
        width: 350px;
      }
    }
  }
}

@media screen and (max-width: 1020px) {
  .frequently-questions-container {
    width: 100%;
    height: 1500px;
    display: flex;
    justify-content: space-around;

    .center-banner-frequently-questions {
      margin-top: 20px;
      width: 90%;

      .questions-placeholder {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
        height: 650px;
        background-color: #2a324b;
        border-radius: 30px;

        h2 {
          color: white;
          font-size: 1rem;
          text-align: center;
        }

        p{
          font-size: 1rem;
        }
      }

      .last-offer-placeholder {
        margin-top: 50px;
        width: 100%;
        height: 700px;
        background-color: #841b17;
        border-radius: 30px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-around;
  
        .text-zone {
          display: flex;
          flex-direction: column;
          max-width: 80%;
          color: white;
  
          h1 {
            font-size: 1.5rem;
            font-weight: 500;
          }
  
          p {
            margin-bottom: 30px;
            letter-spacing: 1px;
            max-width: 90%;
          }
  
          a {
            font-size: 1rem;
            align-self: center;
            width: 50%;
          }
        }
  
        img {
          width: 250px;
        }
      }
    }
  }
}
