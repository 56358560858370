.card {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  border-radius: 30px;
  background-color: #ffffff;

  .question-placeholder {
    width: 100%;
    background-color: #edeff4;
    -webkit-border-top-left-radius: 30px;
    -webkit-border-top-right-radius: 30px;
    -moz-border-radius-topleft: 30px;
    -moz-border-radius-topright: 30px;
    border-top-left-radius: 30px;
    border-top-right-radius: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 80px;

    h1 {
      font-size: 1rem;
      text-align: center;
      max-width: 90%;
    }
  }
  
  p{
    margin-top: 40px;
    font-size: 1.4rem;
    text-align: center;
    max-width: 80%;
  }
}

//mobile
@media screen and (max-width: 1020px) {

}
