.swiper-container {
  height: 90%;
  width: 95%;

  .swiper {
    height: 100%;
    width: 100%;
    --swiper-navigation-size: 20px;
    --swiper-navigation-color: black;

    .swiper-slide {
      height: 80%;
      align-self: center;
    }
  }
}