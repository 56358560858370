.landing-container {
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;

  .side-banner-landing-container {
    border-radius: 20px;
    right: 0;
    position: fixed;
    width: 15%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 30px;

    .signature {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      gap: 50px;
    }
    img {
      width: 80%;
    }
  }

  .side-banner-landing-container:nth-child(3) {
    justify-content: center;
    left: 0;

    p {
      font-size: 1rem;
      max-width: 50%;
      text-align: center;
      padding: 20px;
      background-color: #921f1c;
      color: white;
      text-transform: uppercase;
      border-radius: 30px;
    }

    .text-zone {
      text-transform: uppercase;
      text-align: center;
      font-size: 0.9rem;
    }

    svg:nth-child(3) {
      font-size: 30px;
      
    }

    a {
      
      svg:nth-child(1) {
        cursor: pointer;
        margin-top: 30px;
        font-size: 50px;
        padding: 10px;
        border-radius: 10px;
        background-color: #921f1c;

        &:hover {
          transform: scale(105%);
          transition: 0.8s;
        }
      }
    }
  }

  .center-banner-landing-container {
    background-color: white;
    width: 68%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    h1 {
      font-size: 1.8rem;
    }
  }
}

.mobile-header {
  display: none;
}

//tablet
@media screen and (max-width: 1200px) {
  .landing-container {
    height: auto;
    display: flex;

    .side-banner-landing-container {
      display: none;
    }

    .center-banner-landing-container {
      width: 90%;
      height: 100;
      margin-bottom: 15%;

      h1 {
        font-size: 1.2rem;
        margin-top: 25px;
        margin-bottom: 15px;
      }
    }
  }

  .mobile-header {
    position: inherit;
    display: flex;
    top: 0;
    gap: 25px;
    width: 100%;
    height: 80px;
    align-items: center;
    justify-content: center;
    padding-top: 10px;
    background-color: #1d1d1d;

    p {
      border: 1px solid white;
      text-align: center;
      background-image: linear-gradient(
        to right,
        #462523 0,
        #cb9b51 2%,
        #f6e27a 45%,
        #f6f2c0 50%,
        #f6e27a 55%,
        #cb9b51 98%,
        #462523 100%
      );
      color: transparent;
      -webkit-background-clip: text;
      font-weight: thin;
      background-color: #921f1c;
      padding: 5px;
      border-radius: 10px;
      font-size: 0.7rem;
      max-width: 40%;
      text-transform: uppercase;
    }

    h1 {
      color: white;
      font-size: 0.8rem;
    }

    .text-zone {
      text-transform: uppercase;
      text-align: center;
      flex-direction: row;
    }

    svg:nth-child(3) {
      font-size: 15px;
      transform: rotate(-90deg);
      color: white;
    }

    svg:nth-child(1) {
      font-size: 25px;
      background-image: linear-gradient(
        to right,
        #462523 0,
        #cb9b51 22%,
        #f6e27a 45%,
        #f6e27a 55%,
        #cb9b51 80%,
        #462523 100%
      );
      padding: 10px;
      border-radius: 10px;
    }
  }
}
