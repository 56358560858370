.buying-cards-placeholder {
  height: 100%;
  width: 100%;
  gap: 10px;
  display: flex;
  align-items: center;
  margin-bottom: 10px;

  .buying-card {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 35%;
    height: 100%;
    background-color: white;
    border: 1px solid #b0171a;
    border-radius: 40px;

    p:nth-child(1) {
      font-size: 1.3rem;
      font-weight: bold;
      margin-bottom: 10px;
    }

    p:nth-child(2) {
      font-size: 0.8rem;
      margin-top: 0;
      margin-bottom: 5px;
    }

    img:nth-child(3) {
      width: 60%;
    }

    .price-placeholder {
      display: flex;
      align-items: center;
      margin: 0px;
      max-height: 12%;

      h1 {
        font-size: 2.5rem;
        font-weight: 600;
        margin-right: 10px;
      }

      p:nth-child(2) {
        font-size: 0.7rem;
        max-width: 10px;
      }
    }

    p:nth-child(5) {
      text-transform: uppercase;
      font-size: 0.8rem;
      font-weight: bold;
      text-align: center;
    }

    span {
      font-size: 0.8rem;
      font-weight: 500;
    }

    p:nth-child(7) {
      font-size: 0.8rem;
      margin: 5px;
    }

    p:nth-child(9) {
      font-size: 0.8rem;
      margin: 5px;
    }

    p:nth-child(10) {
      font-size: 0.8rem;
      margin: 5px;
    }

    a {
      color: black;
      text-decoration: none;
      text-transform: uppercase;
      background-color: #ffd814;
      text-align: center;
      width: 50%;
      padding: 10px;
      border-radius: 30px;
      font-size: 0.9rem;
      font-weight: bold;
    }

    p:nth-child(7) {
      font-size: 0.8rem;
      margin-top: 15px;
      font-weight: bold;
    }

    img:nth-child(8) {
      width: 60%;
      margin: 10px;
    }

    p:nth-child(9) {
      font-size: 1rem;
      margin-top: 15px;

      span:nth-child(1) {
        font-size: 1rem;
        font-weight: bold;
        text-decoration: line-through;
      }
    }

    p:nth-child(10) {
      font-size: 0.7rem;
      font-weight: bold;
    }

    //mobile only
    #money-back-seal {
      display: none;
    }

    #signature-card{
      display: none;
    }
  }
}

//mobile
@media screen and (max-width: 1020px) {
  .buying-cards-placeholder {
    flex-direction: column;

    .buying-card {
      margin-top: 10px;
      position: relative;
      padding-bottom: 20px;
      width: 100%;
      height: auto;

      #money-back-seal {
        display: inherit;
        position: absolute;
        top: -6px;
        right: -12px;
        width: 25%;
      }

      #signature-card {
        display: inherit;
        position: absolute;
        bottom: -6px;
        right: -12px;
        width: 30%;
      }

      img {
        display: flex;
      }
    }
  }
}
